import React, { Component } from 'react';
import './MenuContainer.scss';


export class Item extends Component{
    render(){
        const style = {};
        if(this.props.width){
            style.width = this.props.width;
        }
        if(this.props.flex){
            style.flex = this.props.flex;
        }
        return(
            <div className="menu-item-content" style={style}>
                {this.props.children}
            </div>
        )
    }
}

export default class MenuContainer extends Component {
    render() {
        return (
            <div className="menu-item">
                {this.props.children}
            </div>
        )
    }
}
