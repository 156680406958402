import React, { Component } from 'react';
import { Select, Button, Modal, Collapse } from 'antd';
import { Fade } from 'react-reveal';
import './MenuRegister.scss';

export default class MenuRegister extends Component {
    state = {
        listDomains: [],
        selected: null,
        childLevel3: [],
        childLevel2: [],
        urlReplace: "",
        visible: false
    }

    async componentDidMount() {
        try{
            // const response = await fetch('https://qlts.tsdc.edu.vn/landing-config');
            // const json = await response.json();

            let json = [
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh An Giang",
                    "maDonVi": "89",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://angiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện An Phú",
                            "maDonVi": "886",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://angiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/886",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Châu Thành",
                            "maDonVi": "892",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://angiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/892",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Châu Phú",
                            "maDonVi": "889",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://angiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/889",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Chợ Mới",
                            "maDonVi": "893",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://angiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/893",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Châu Đốc",
                            "maDonVi": "884",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://angiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/884",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Long Xuyên",
                            "maDonVi": "883",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://angiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/883",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Tân Châu",
                            "maDonVi": "887",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://angiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/887",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phú Tân",
                            "maDonVi": "888",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://angiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/888",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thoại Sơn",
                            "maDonVi": "894",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://angiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/894",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tri Tôn",
                            "maDonVi": "891",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://angiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/891",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tịnh Biên",
                            "maDonVi": "890",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://angiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/890",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Bà Rịa - Vũng Tàu",
                    "maDonVi": "77",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://bariavungtau.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Châu Đức",
                            "maDonVi": "750",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bariavungtau.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/750",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Côn Đảo",
                            "maDonVi": "755",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bariavungtau.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/755",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Long Điền",
                            "maDonVi": "752",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bariavungtau.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/752",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Bà Rịa",
                            "maDonVi": "748",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bariavungtau.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/748",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Vũng Tàu",
                            "maDonVi": "747",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bariavungtau.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/747",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tân Thành",
                            "maDonVi": "754",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bariavungtau.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/754",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Xuyên Mộc",
                            "maDonVi": "751",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bariavungtau.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/751",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đất Đỏ",
                            "maDonVi": "753",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bariavungtau.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/753",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Bạc Liêu",
                    "maDonVi": "95",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://baclieu.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hoà Bình",
                            "maDonVi": "961",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://baclieu.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/961",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hồng Dân",
                            "maDonVi": "956",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://baclieu.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/956",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Bạc Liêu",
                            "maDonVi": "954",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://baclieu.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/954",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Giá Rai",
                            "maDonVi": "959",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://baclieu.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/959",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phước Long",
                            "maDonVi": "957",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://baclieu.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/957",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Vĩnh Lợi",
                            "maDonVi": "958",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://baclieu.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/958",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đông Hải",
                            "maDonVi": "960",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://baclieu.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/960",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Bắc Giang",
                    "maDonVi": "24",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://bacgiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hiệp Hòa",
                            "maDonVi": "223",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bacgiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/223",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lạng Giang",
                            "maDonVi": "217",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bacgiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/217",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lục Ngạn",
                            "maDonVi": "219",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bacgiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/219",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lục Nam",
                            "maDonVi": "218",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bacgiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/218",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Bắc Giang",
                            "maDonVi": "213",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bacgiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/213",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Sơn Động",
                            "maDonVi": "220",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bacgiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/220",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tân Yên",
                            "maDonVi": "216",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bacgiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/216",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Việt Yên",
                            "maDonVi": "222",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bacgiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/222",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Yên Dũng",
                            "maDonVi": "221",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bacgiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/221",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Yên Thế",
                            "maDonVi": "215",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bacgiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/215",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Bắc Kạn",
                    "maDonVi": "06",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://backan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ba Bể",
                            "maDonVi": "061",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://backan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/061",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bạch Thông",
                            "maDonVi": "063",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://backan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/063",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Chợ Mới",
                            "maDonVi": "065",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://backan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/065",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Chợ Đồn",
                            "maDonVi": "064",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://backan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/064",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Na Rì",
                            "maDonVi": "066",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://backan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/066",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ngân Sơn",
                            "maDonVi": "062",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://backan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/062",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành Phố Bắc Kạn",
                            "maDonVi": "058",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://backan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/058",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Pác Nặm",
                            "maDonVi": "060",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://backan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/060",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Bắc Ninh",
                    "maDonVi": "27",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://bacninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Gia Bình",
                            "maDonVi": "263",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bacninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/263",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lương Tài",
                            "maDonVi": "264",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bacninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/264",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Bắc Ninh",
                            "maDonVi": "256",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bacninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/256",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Từ Sơn",
                            "maDonVi": "261",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bacninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/261",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Quế Võ",
                            "maDonVi": "259",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bacninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/259",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thuận Thành",
                            "maDonVi": "262",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bacninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/262",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tiên Du",
                            "maDonVi": "260",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bacninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/260",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Yên Phong",
                            "maDonVi": "258",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bacninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/258",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Bến Tre",
                    "maDonVi": "83",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://bentre.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ba Tri",
                            "maDonVi": "836",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bentre.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/836",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bình Đại",
                            "maDonVi": "835",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bentre.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/835",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Châu Thành",
                            "maDonVi": "831",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bentre.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/831",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Chợ Lách",
                            "maDonVi": "832",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bentre.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/832",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Giồng Trôm",
                            "maDonVi": "834",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bentre.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/834",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mỏ Cày Bắc",
                            "maDonVi": "838",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bentre.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/838",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mỏ Cày Nam",
                            "maDonVi": "833",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bentre.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/833",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Bến Tre",
                            "maDonVi": "829",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bentre.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/829",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thạnh Phú",
                            "maDonVi": "837",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://bentre.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/837",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Bình Định",
                    "maDonVi": "52",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://binhdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện An Lão",
                            "maDonVi": "542",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/542",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hoài Ân",
                            "maDonVi": "544",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/544",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hoài Nhơn",
                            "maDonVi": "543",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/543",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Qui Nhơn",
                            "maDonVi": "540",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/540",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã An Nhơn",
                            "maDonVi": "549",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/549",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phù Cát",
                            "maDonVi": "548",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/548",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phù Mỹ",
                            "maDonVi": "545",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/545",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tuy Phước",
                            "maDonVi": "550",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/550",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tây Sơn",
                            "maDonVi": "547",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/547",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Vân Canh",
                            "maDonVi": "551",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/551",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Vĩnh Thạnh",
                            "maDonVi": "546",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/546",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Bình Phước",
                    "maDonVi": "70",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://binhphuoc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bù Đăng",
                            "maDonVi": "696",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhphuoc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/696",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bù Đốp",
                            "maDonVi": "693",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhphuoc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/693",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bù Gia Mập",
                            "maDonVi": "691",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhphuoc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/691",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Chơn Thành",
                            "maDonVi": "697",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhphuoc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/697",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hớn Quản",
                            "maDonVi": "694",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhphuoc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/694",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lộc Ninh",
                            "maDonVi": "692",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhphuoc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/692",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Bình Long",
                            "maDonVi": "690",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhphuoc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/690",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Phước Long",
                            "maDonVi": "688",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhphuoc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/688",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Đồng Xoài",
                            "maDonVi": "689",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhphuoc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/689",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phú Riềng",
                            "maDonVi": "698",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhphuoc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/698",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đồng Phú",
                            "maDonVi": "695",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhphuoc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/695",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Bình Dương",
                    "maDonVi": "74",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://binhduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bàu Bàng",
                            "maDonVi": "719",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/719",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bắc Tân Uyên",
                            "maDonVi": "726",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/726",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Dầu Tiếng",
                            "maDonVi": "720",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/720",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Thủ Dầu Một",
                            "maDonVi": "718",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/718",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Bến Cát",
                            "maDonVi": "721",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/721",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Dĩ An",
                            "maDonVi": "724",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/724",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Thuận An",
                            "maDonVi": "725",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/725",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Tân Uyên",
                            "maDonVi": "723",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/723",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phú Giáo",
                            "maDonVi": "722",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/722",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Bình Thuận",
                    "maDonVi": "60",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://binhthuan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bắc Bình",
                            "maDonVi": "596",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhthuan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/596",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hàm Tân",
                            "maDonVi": "601",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhthuan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/601",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hàm Thuận Nam",
                            "maDonVi": "598",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhthuan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/598",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hàm Thuận Bắc",
                            "maDonVi": "597",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhthuan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/597",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Phan Thiết",
                            "maDonVi": "593",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhthuan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/593",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã La Gi",
                            "maDonVi": "594",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhthuan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/594",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phú Quí",
                            "maDonVi": "602",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhthuan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/602",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tuy Phong",
                            "maDonVi": "595",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhthuan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/595",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tánh Linh",
                            "maDonVi": "599",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhthuan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/599",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đức Linh",
                            "maDonVi": "600",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://binhthuan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/600",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Cà Mau",
                    "maDonVi": "96",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://camau-thpt.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "title": "Hệ thống mầm non, lớp 1, lớp 6",
                            "url": "https://camau.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                            "level": 2
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Cao Bằng",
                    "maDonVi": "04",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://caobang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bảo Lạc",
                            "maDonVi": "043",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://caobang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/043",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bảo Lâm",
                            "maDonVi": "042",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://caobang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/042",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hoà An",
                            "maDonVi": "051",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://caobang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/051",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hà Quảng",
                            "maDonVi": "045",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://caobang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/045",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hạ Lang",
                            "maDonVi": "048",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://caobang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/048",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nguyên Bình",
                            "maDonVi": "052",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://caobang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/052",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Cao Bằng",
                            "maDonVi": "040",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://caobang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/040",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phục Hoà",
                            "maDonVi": "050",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://caobang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/050",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Quảng Uyên",
                            "maDonVi": "049",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://caobang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/049",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thông Nông",
                            "maDonVi": "044",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://caobang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/044",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thạch An",
                            "maDonVi": "053",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://caobang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/053",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Trà Lĩnh",
                            "maDonVi": "046",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://caobang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/046",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Trùng Khánh",
                            "maDonVi": "047",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://caobang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/047",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Thành phố Cần Thơ",
                    "maDonVi": "92",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://cantho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cờ Đỏ",
                            "maDonVi": "925",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://cantho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/925",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phong Điền",
                            "maDonVi": "926",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://cantho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/926",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Bình Thuỷ",
                            "maDonVi": "918",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://cantho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/918",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Cái Răng",
                            "maDonVi": "919",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://cantho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/919",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Ninh Kiều",
                            "maDonVi": "916",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://cantho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/916",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Thốt Nốt",
                            "maDonVi": "923",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://cantho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/923",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Ô Môn",
                            "maDonVi": "917",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://cantho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/917",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thới Lai",
                            "maDonVi": "927",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://cantho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/927",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Vĩnh Thạnh",
                            "maDonVi": "924",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://cantho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/924",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Thành phố Đà Nẵng",
                    "maDonVi": "48",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://danang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hòa Vang",
                            "maDonVi": "497",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://danang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/497",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Cẩm Lệ",
                            "maDonVi": "495",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://danang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/495",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Hải Châu",
                            "maDonVi": "492",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://danang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/492",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Liên Chiểu",
                            "maDonVi": "490",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://danang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/490",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Ngũ Hành Sơn",
                            "maDonVi": "494",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://danang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/494",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Sơn Trà",
                            "maDonVi": "493",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://danang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/493",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Thanh Khê",
                            "maDonVi": "491",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://danang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/491",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Đắk Nông",
                    "maDonVi": "67",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://daknong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cư Jút",
                            "maDonVi": "662",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daknong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/662",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Krông Nô",
                            "maDonVi": "664",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daknong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/664",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Gia Nghĩa",
                            "maDonVi": "660",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daknong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/660",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tuy Đức",
                            "maDonVi": "667",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daknong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/667",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đăk Glong",
                            "maDonVi": "661",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daknong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/661",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đắk RLấp",
                            "maDonVi": "666",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daknong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/666",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đắk Song",
                            "maDonVi": "665",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daknong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/665",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đắk Mil",
                            "maDonVi": "663",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daknong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/663",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Đắk Lắk",
                    "maDonVi": "66",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://daklak-tsthpt.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Buôn Đôn",
                            "maDonVi": "647",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daklak.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/647",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cư Kuin",
                            "maDonVi": "657",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daklak.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/657",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cư Mgar",
                            "maDonVi": "648",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daklak.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/648",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ea Kar",
                            "maDonVi": "651",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daklak.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/651",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ea Súp",
                            "maDonVi": "646",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daklak.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/646",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ea Hleo",
                            "maDonVi": "645",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daklak.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/645",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Krông A Na",
                            "maDonVi": "655",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daklak.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/655",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Krông Pắc",
                            "maDonVi": "654",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daklak.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/654",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Krông Bông",
                            "maDonVi": "653",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daklak.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/653",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Krông Năng",
                            "maDonVi": "650",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daklak.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/650",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Krông Búk",
                            "maDonVi": "649",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daklak.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/649",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lắk",
                            "maDonVi": "656",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daklak.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/656",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện MĐrắk",
                            "maDonVi": "652",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daklak.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/652",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Buôn Ma Thuột",
                            "maDonVi": "643",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daklak.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/643",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị Xã Buôn Hồ",
                            "maDonVi": "644",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://daklak.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/644",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Điện Biên",
                    "maDonVi": "11",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://dienbien.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mường Ảng",
                            "maDonVi": "102",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dienbien.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/102",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mường Chà",
                            "maDonVi": "097",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dienbien.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/097",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mường Nhé",
                            "maDonVi": "096",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dienbien.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/096",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nậm Pồ",
                            "maDonVi": "103",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dienbien.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/103",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Điện Biên Phủ",
                            "maDonVi": "094",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dienbien.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/094",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị Xã Mường Lay",
                            "maDonVi": "095",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dienbien.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/095",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tuần Giáo",
                            "maDonVi": "099",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dienbien.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/099",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tủa Chùa",
                            "maDonVi": "098",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dienbien.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/098",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Điện Biên Đông",
                            "maDonVi": "101",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dienbien.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/101",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Điện Biên",
                            "maDonVi": "100",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dienbien.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/100",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Đồng Nai",
                    "maDonVi": "75",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://dongnai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cẩm Mỹ",
                            "maDonVi": "739",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongnai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/739",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Long Thành",
                            "maDonVi": "740",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongnai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/740",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nhơn Trạch",
                            "maDonVi": "742",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongnai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/742",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Biên Hòa",
                            "maDonVi": "731",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongnai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/731",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Long Khánh",
                            "maDonVi": "732",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongnai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/732",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thống Nhất",
                            "maDonVi": "738",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongnai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/738",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Trảng Bom",
                            "maDonVi": "737",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongnai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/737",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tân Phú",
                            "maDonVi": "734",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongnai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/734",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Vĩnh Cửu",
                            "maDonVi": "735",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongnai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/735",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Xuân Lộc",
                            "maDonVi": "741",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongnai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/741",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Định Quán",
                            "maDonVi": "736",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongnai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/736",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Đồng Tháp",
                    "maDonVi": "87",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://dongthap.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cao Lãnh",
                            "maDonVi": "873",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongthap.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/873",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Châu Thành",
                            "maDonVi": "877",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongthap.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/877",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hồng Ngự",
                            "maDonVi": "870",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongthap.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/870",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lai Vung",
                            "maDonVi": "876",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongthap.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/876",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lấp Vò",
                            "maDonVi": "875",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongthap.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/875",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Cao Lãnh",
                            "maDonVi": "866",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongthap.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/866",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Sa Đéc",
                            "maDonVi": "867",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongthap.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/867",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Hồng Ngự",
                            "maDonVi": "868",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongthap.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/868",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tam Nông",
                            "maDonVi": "871",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongthap.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/871",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thanh Bình",
                            "maDonVi": "874",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongthap.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/874",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tháp Mười",
                            "maDonVi": "872",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongthap.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/872",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tân Hồng",
                            "maDonVi": "869",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://dongthap.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/869",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Gia Lai",
                    "maDonVi": "64",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://gialai-tsthpt.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Chư Pưh",
                            "maDonVi": "639",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://gialai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/639",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Chư Sê",
                            "maDonVi": "633",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://gialai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/633",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Chư Prông",
                            "maDonVi": "632",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://gialai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/632",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ia Pa",
                            "maDonVi": "635",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://gialai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/635",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ia Grai",
                            "maDonVi": "628",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://gialai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/628",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện KBang",
                            "maDonVi": "625",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://gialai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/625",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Krông Pa",
                            "maDonVi": "637",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://gialai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/637",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Kông Chro",
                            "maDonVi": "630",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://gialai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/630",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mang Yang",
                            "maDonVi": "629",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://gialai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/629",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Pleiku",
                            "maDonVi": "622",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://gialai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/622",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã An Khê",
                            "maDonVi": "623",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://gialai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/623",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Ayun Pa",
                            "maDonVi": "624",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://gialai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/624",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo huyện Chư Păh",
                            "maDonVi": "627",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://gialai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/627",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phú Thiện",
                            "maDonVi": "638",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://gialai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/638",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đăk Pơ",
                            "maDonVi": "634",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://gialai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/634",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đăk Đoa",
                            "maDonVi": "626",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://gialai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/626",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đức Cơ",
                            "maDonVi": "631",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://gialai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/631",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Hà Nam",
                    "maDonVi": "35",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://hanam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bình Lục",
                            "maDonVi": "352",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/352",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Duy Tiên",
                            "maDonVi": "349",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/349",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Kim Bảng",
                            "maDonVi": "350",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/350",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lý Nhân",
                            "maDonVi": "353",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/353",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Phủ Lý",
                            "maDonVi": "347",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/347",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thanh Liêm",
                            "maDonVi": "351",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/351",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Hà Giang",
                    "maDonVi": "02",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://hagiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bắc Quang",
                            "maDonVi": "034",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hagiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/034",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bắc Mê",
                            "maDonVi": "031",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hagiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/031",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hoàng Su Phì",
                            "maDonVi": "032",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hagiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/032",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mèo Vạc",
                            "maDonVi": "027",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hagiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/027",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Hà Giang",
                            "maDonVi": "024",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hagiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/024",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Quang Bình",
                            "maDonVi": "035",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hagiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/035",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Quản Bạ",
                            "maDonVi": "029",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hagiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/029",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Vị Xuyên",
                            "maDonVi": "030",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hagiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/030",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Xín Mần",
                            "maDonVi": "033",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hagiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/033",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Yên Minh",
                            "maDonVi": "028",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hagiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/028",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đồng Văn",
                            "maDonVi": "026",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hagiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/026",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Thành phố Hà Nội",
                    "maDonVi": "01",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ba Vì",
                            "maDonVi": "271",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/271",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Chương Mỹ",
                            "maDonVi": "277",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/277",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Gia Lâm",
                            "maDonVi": "018",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/018",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hoài Đức",
                            "maDonVi": "274",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/274",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mê Linh",
                            "maDonVi": "250",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/250",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mỹ Đức",
                            "maDonVi": "282",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/282",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Ba Đình",
                            "maDonVi": "001",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/001",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Bắc Từ Liêm",
                            "maDonVi": "021",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/021",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Cầu Giấy",
                            "maDonVi": "005",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/005",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Hai Bà Trưng",
                            "maDonVi": "007",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/007",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Hoàn Kiếm",
                            "maDonVi": "002",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/002",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Hoàng Mai",
                            "maDonVi": "008",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/008",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Hà Đông",
                            "maDonVi": "268",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/268",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Long Biên",
                            "maDonVi": "004",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/004",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Nam Từ Liêm",
                            "maDonVi": "019",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/019",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Thanh Xuân",
                            "maDonVi": "009",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/009",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Tây Hồ",
                            "maDonVi": "003",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/003",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Đống Đa",
                            "maDonVi": "006",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/006",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Sơn Tây",
                            "maDonVi": "269",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/269",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phú Xuyên",
                            "maDonVi": "280",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/280",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phúc Thọ",
                            "maDonVi": "272",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/272",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Quốc Oai",
                            "maDonVi": "275",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/275",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Sóc Sơn",
                            "maDonVi": "016",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/016",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thanh Oai",
                            "maDonVi": "278",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/278",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thanh Trì",
                            "maDonVi": "020",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/020",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thường Tín",
                            "maDonVi": "279",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/279",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thạch Thất",
                            "maDonVi": "276",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/276",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đan Phượng",
                            "maDonVi": "273",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/273",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đông Anh",
                            "maDonVi": "017",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/017",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ứng Hòa",
                            "maDonVi": "281",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hanoi.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/281",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Hà Tĩnh",
                    "maDonVi": "42",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://hatinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Can Lộc",
                            "maDonVi": "443",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hatinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/443",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cẩm Xuyên",
                            "maDonVi": "446",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hatinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/446",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hương Khê",
                            "maDonVi": "444",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hatinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/444",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hương Sơn",
                            "maDonVi": "439",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hatinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/439",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Kỳ Anh",
                            "maDonVi": "447",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hatinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/447",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lộc Hà",
                            "maDonVi": "448",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hatinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/448",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nghi Xuân",
                            "maDonVi": "442",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hatinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/442",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Hà Tĩnh",
                            "maDonVi": "436",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hatinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/436",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Hồng Lĩnh",
                            "maDonVi": "437",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hatinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/437",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Kỳ Anh",
                            "maDonVi": "449",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hatinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/449",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thạch Hà",
                            "maDonVi": "445",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hatinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/445",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Vũ Quang",
                            "maDonVi": "441",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hatinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/441",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đức Thọ",
                            "maDonVi": "440",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hatinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/440",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Hải Dương",
                    "maDonVi": "30",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://haiduong-thpt.tsdc.edu.vn",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bình Giang",
                            "maDonVi": "296",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/296",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cẩm Giàng",
                            "maDonVi": "295",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/295",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Gia Lộc",
                            "maDonVi": "297",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/297",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Kim Thành",
                            "maDonVi": "293",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/293",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Kinh Môn",
                            "maDonVi": "292",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/292",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nam Sách",
                            "maDonVi": "291",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/291",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ninh Giang",
                            "maDonVi": "299",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/299",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Hải Dương",
                            "maDonVi": "288",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/288",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Chí Linh",
                            "maDonVi": "290",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/290",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thanh Miện",
                            "maDonVi": "300",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/300",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thanh Hà",
                            "maDonVi": "294",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/294",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tứ Kỳ",
                            "maDonVi": "298",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiduong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/298",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Thành phố Hải Phòng",
                    "maDonVi": "31",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://haiphong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện An Dương",
                            "maDonVi": "312",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiphong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/312",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện An Lão",
                            "maDonVi": "313",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiphong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/313",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cát Hải",
                            "maDonVi": "317",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiphong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/317",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Kiến Thuỵ",
                            "maDonVi": "314",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiphong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/314",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Dương Kinh",
                            "maDonVi": "309",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiphong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/309",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Hải An",
                            "maDonVi": "306",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiphong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/306",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Hồng Bàng",
                            "maDonVi": "303",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiphong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/303",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Kiến An",
                            "maDonVi": "307",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiphong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/307",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Lê Chân",
                            "maDonVi": "305",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiphong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/305",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Ngô Quyền",
                            "maDonVi": "304",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiphong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/304",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Đồ Sơn",
                            "maDonVi": "308",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiphong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/308",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thuỷ Nguyên",
                            "maDonVi": "311",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiphong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/311",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tiên Lãng",
                            "maDonVi": "315",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiphong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/315",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Vĩnh Bảo",
                            "maDonVi": "316",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haiphong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/316",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Hậu Giang",
                    "maDonVi": "93",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://haugiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Châu Thành",
                            "maDonVi": "933",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haugiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/933",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Châu Thành A",
                            "maDonVi": "932",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haugiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/932",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Long Mỹ",
                            "maDonVi": "936",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haugiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/936",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Vị Thanh",
                            "maDonVi": "930",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haugiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/930",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Long Mỹ",
                            "maDonVi": "937",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haugiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/937",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Ngã Bảy",
                            "maDonVi": "931",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haugiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/931",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phụng Hiệp",
                            "maDonVi": "934",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haugiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/934",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Vị Thuỷ",
                            "maDonVi": "935",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://haugiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/935",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Hòa Bình",
                    "maDonVi": "17",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://hoabinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cao Phong",
                            "maDonVi": "154",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hoabinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/154",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Kim Bôi",
                            "maDonVi": "153",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hoabinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/153",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Kỳ Sơn",
                            "maDonVi": "151",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hoabinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/151",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lương Sơn",
                            "maDonVi": "152",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hoabinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/152",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lạc Thủy",
                            "maDonVi": "159",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hoabinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/159",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lạc Sơn",
                            "maDonVi": "157",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hoabinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/157",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mai Châu",
                            "maDonVi": "156",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hoabinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/156",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Hòa Bình",
                            "maDonVi": "148",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hoabinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/148",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tân Lạc",
                            "maDonVi": "155",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hoabinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/155",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Yên Thủy",
                            "maDonVi": "158",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hoabinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/158",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đà Bắc",
                            "maDonVi": "150",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hoabinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/150",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Thành phố Hồ Chí Minh",
                    "maDonVi": "79",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bình Chánh",
                            "maDonVi": "785",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/785",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cần Giờ",
                            "maDonVi": "787",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/787",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Củ Chi",
                            "maDonVi": "783",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/783",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hóc Môn",
                            "maDonVi": "784",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/784",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nhà Bè",
                            "maDonVi": "786",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/786",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận 1",
                            "maDonVi": "760",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/760",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận 10",
                            "maDonVi": "771",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/771",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận 11",
                            "maDonVi": "772",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/772",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận 12",
                            "maDonVi": "761",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/761",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận 2",
                            "maDonVi": "769",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/769",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận 3",
                            "maDonVi": "770",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/770",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận 4",
                            "maDonVi": "773",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/773",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận 5",
                            "maDonVi": "774",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/774",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận 6",
                            "maDonVi": "775",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/775",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận 7",
                            "maDonVi": "778",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/778",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận 8",
                            "maDonVi": "776",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/776",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận 9",
                            "maDonVi": "763",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/763",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Bình Thạnh",
                            "maDonVi": "765",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/765",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Bình Tân",
                            "maDonVi": "777",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/777",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Gò Vấp",
                            "maDonVi": "764",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/764",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Phú Nhuận",
                            "maDonVi": "768",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/768",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Thủ Đức",
                            "maDonVi": "762",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/762",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Tân Bình",
                            "maDonVi": "766",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/766",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Quận Tân Phú",
                            "maDonVi": "767",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hcm.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/767",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Hưng Yên",
                    "maDonVi": "33",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://hungyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Khoái Châu",
                            "maDonVi": "330",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hungyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/330",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Kim Động",
                            "maDonVi": "331",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hungyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/331",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mỹ Hào",
                            "maDonVi": "328",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hungyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/328",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Hưng Yên",
                            "maDonVi": "323",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hungyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/323",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phù Cừ",
                            "maDonVi": "333",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hungyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/333",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tiên Lữ",
                            "maDonVi": "332",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hungyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/332",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Văn Giang",
                            "maDonVi": "326",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hungyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/326",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Văn Lâm",
                            "maDonVi": "325",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hungyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/325",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Yên Mỹ",
                            "maDonVi": "327",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hungyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/327",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ân Thi",
                            "maDonVi": "329",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://hungyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/329",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Khánh Hòa",
                    "maDonVi": "56",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://khanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cam Lâm",
                            "maDonVi": "570",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://khanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/570",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Diên Khánh",
                            "maDonVi": "574",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://khanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/574",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Khánh Sơn",
                            "maDonVi": "575",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://khanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/575",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Khánh Vĩnh",
                            "maDonVi": "573",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://khanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/573",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Cam Ranh",
                            "maDonVi": "569",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://khanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/569",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Nha Trang",
                            "maDonVi": "568",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://khanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/568",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Ninh Hòa",
                            "maDonVi": "572",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://khanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/572",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Trường Sa",
                            "maDonVi": "576",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://khanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/576",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Vạn Ninh",
                            "maDonVi": "571",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://khanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/571",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Kiên Giang",
                    "maDonVi": "91",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://kiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện An Minh",
                            "maDonVi": "909",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/909",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện An Biên",
                            "maDonVi": "908",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/908",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Châu Thành",
                            "maDonVi": "905",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/905",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Giang Thành",
                            "maDonVi": "914",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/914",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Giồng Riềng",
                            "maDonVi": "906",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/906",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Gò Quao",
                            "maDonVi": "907",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/907",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hòn Đất",
                            "maDonVi": "903",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/903",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Kiên Hải",
                            "maDonVi": "912",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/912",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Kiên Lương",
                            "maDonVi": "902",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/902",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Rạch Giá",
                            "maDonVi": "899",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/899",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Hà Tiên",
                            "maDonVi": "900",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/900",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phú Quốc",
                            "maDonVi": "911",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/911",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tân Hiệp",
                            "maDonVi": "904",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/904",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện U Minh Thượng",
                            "maDonVi": "913",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/913",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Vĩnh Thuận",
                            "maDonVi": "910",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/910",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Kon Tum",
                    "maDonVi": "62",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://kontum.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ia H Drai",
                            "maDonVi": "618",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kontum.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/618",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Kon Rẫy",
                            "maDonVi": "614",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kontum.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/614",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Kon Plông",
                            "maDonVi": "613",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kontum.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/613",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ngọc Hồi",
                            "maDonVi": "611",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kontum.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/611",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Kon Tum",
                            "maDonVi": "608",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kontum.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/608",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Sa Thầy",
                            "maDonVi": "616",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kontum.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/616",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tu Mơ Rông",
                            "maDonVi": "617",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kontum.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/617",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đắk Hà",
                            "maDonVi": "615",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kontum.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/615",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đắk Tô",
                            "maDonVi": "612",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kontum.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/612",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đắk Glei",
                            "maDonVi": "610",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://kontum.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/610",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Lai Châu",
                    "maDonVi": "12",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://laichau.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mường Tè",
                            "maDonVi": "107",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://laichau.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/107",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nậm Nhùn",
                            "maDonVi": "112",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://laichau.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/112",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phong Thổ",
                            "maDonVi": "109",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://laichau.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/109",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Lai Châu",
                            "maDonVi": "105",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://laichau.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/105",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Sìn Hồ",
                            "maDonVi": "108",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://laichau.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/108",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tam Đường",
                            "maDonVi": "106",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://laichau.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/106",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Than Uyên",
                            "maDonVi": "110",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://laichau.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/110",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tân Uyên",
                            "maDonVi": "111",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://laichau.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/111",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Lạng Sơn",
                    "maDonVi": "20",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://langson.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bình Gia",
                            "maDonVi": "181",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://langson.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/181",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bắc Sơn",
                            "maDonVi": "185",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://langson.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/185",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cao Lộc",
                            "maDonVi": "183",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://langson.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/183",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Chi Lăng",
                            "maDonVi": "187",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://langson.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/187",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hữu Lũng",
                            "maDonVi": "186",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://langson.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/186",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lộc Bình",
                            "maDonVi": "188",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://langson.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/188",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Lạng Sơn",
                            "maDonVi": "178",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://langson.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/178",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tràng Định",
                            "maDonVi": "180",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://langson.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/180",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Văn Quan",
                            "maDonVi": "184",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://langson.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/184",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Văn Lãng",
                            "maDonVi": "182",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://langson.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/182",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đình Lập",
                            "maDonVi": "189",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://langson.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/189",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Lào Cai",
                    "maDonVi": "10",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://laocai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bát Xát",
                            "maDonVi": "082",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://laocai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/082",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bảo Yên",
                            "maDonVi": "087",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://laocai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/087",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bảo Thắng",
                            "maDonVi": "086",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://laocai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/086",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bắc Hà",
                            "maDonVi": "085",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://laocai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/085",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mường Khương",
                            "maDonVi": "083",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://laocai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/083",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Lào Cai",
                            "maDonVi": "080",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://laocai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/080",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Sa Pa",
                            "maDonVi": "088",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://laocai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/088",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Si Ma Cai",
                            "maDonVi": "084",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://laocai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/084",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Văn Bàn",
                            "maDonVi": "089",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://laocai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/089",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Lâm Đồng",
                    "maDonVi": "68",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://lamdong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bảo Lâm",
                            "maDonVi": "680",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://lamdong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/680",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cát Tiên",
                            "maDonVi": "683",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://lamdong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/683",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Di Linh",
                            "maDonVi": "679",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://lamdong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/679",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lâm Hà",
                            "maDonVi": "676",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://lamdong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/676",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lạc Dương",
                            "maDonVi": "675",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://lamdong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/675",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Bảo Lộc",
                            "maDonVi": "673",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://lamdong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/673",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Đà Lạt",
                            "maDonVi": "672",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://lamdong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/672",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đam Rông",
                            "maDonVi": "674",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://lamdong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/674",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đơn Dương",
                            "maDonVi": "677",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://lamdong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/677",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đạ Tẻh",
                            "maDonVi": "682",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://lamdong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/682",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đạ Huoai",
                            "maDonVi": "681",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://lamdong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/681",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đức Trọng",
                            "maDonVi": "678",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://lamdong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/678",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Long An",
                    "maDonVi": "80",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://longan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bến Lức",
                            "maDonVi": "803",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://longan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/803",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Châu Thành",
                            "maDonVi": "808",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://longan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/808",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cần Giuộc",
                            "maDonVi": "807",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://longan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/807",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cần Đước",
                            "maDonVi": "806",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://longan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/806",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mộc Hóa",
                            "maDonVi": "798",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://longan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/798",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Tân An",
                            "maDonVi": "794",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://longan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/794",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Kiến Tường",
                            "maDonVi": "795",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://longan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/795",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thạnh Hóa",
                            "maDonVi": "800",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://longan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/800",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thủ Thừa",
                            "maDonVi": "804",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://longan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/804",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tân Trụ",
                            "maDonVi": "805",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://longan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/805",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tân Thạnh",
                            "maDonVi": "799",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://longan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/799",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tân Hưng",
                            "maDonVi": "796",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://longan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/796",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Vĩnh Hưng",
                            "maDonVi": "797",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://longan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/797",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đức Hòa",
                            "maDonVi": "802",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://longan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/802",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đức Huệ",
                            "maDonVi": "801",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://longan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/801",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Nam Định",
                    "maDonVi": "36",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://namdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Giao Thủy",
                            "maDonVi": "365",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://namdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/365",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hải Hậu",
                            "maDonVi": "366",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://namdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/366",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mỹ Lộc",
                            "maDonVi": "358",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://namdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/358",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nam Trực",
                            "maDonVi": "362",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://namdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/362",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nghĩa Hưng",
                            "maDonVi": "361",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://namdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/361",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Nam Định",
                            "maDonVi": "356",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://namdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/356",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Trực Ninh",
                            "maDonVi": "363",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://namdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/363",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Vụ Bản",
                            "maDonVi": "359",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://namdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/359",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Xuân Trường",
                            "maDonVi": "364",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://namdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/364",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ý Yên",
                            "maDonVi": "360",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://namdinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/360",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Nghệ An",
                    "maDonVi": "40",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Anh Sơn",
                            "maDonVi": "424",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/424",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Con Cuông",
                            "maDonVi": "422",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/422",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Diễn Châu",
                            "maDonVi": "425",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/425",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hưng Nguyên",
                            "maDonVi": "431",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/431",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Kỳ Sơn",
                            "maDonVi": "417",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/417",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nam Đàn",
                            "maDonVi": "430",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/430",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nghi Lộc",
                            "maDonVi": "429",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/429",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nghĩa Đàn",
                            "maDonVi": "419",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/419",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Vinh",
                            "maDonVi": "412",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/412",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Cửa Lò",
                            "maDonVi": "413",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/413",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Hoàng Mai",
                            "maDonVi": "432",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/432",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Thái Hoà",
                            "maDonVi": "414",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/414",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Quế Phong",
                            "maDonVi": "415",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/415",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Quỳ Hợp",
                            "maDonVi": "420",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/420",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Quỳ Châu",
                            "maDonVi": "416",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/416",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Quỳnh Lưu",
                            "maDonVi": "421",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/421",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thanh Chương",
                            "maDonVi": "428",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/428",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tân Kỳ",
                            "maDonVi": "423",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/423",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tương Dương",
                            "maDonVi": "418",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/418",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Yên Thành",
                            "maDonVi": "426",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/426",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đô Lương",
                            "maDonVi": "427",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://nghean.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/427",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Ninh Bình",
                    "maDonVi": "37",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://ninhbinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Gia Viễn",
                            "maDonVi": "373",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://ninhbinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/373",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hoa Lư",
                            "maDonVi": "374",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://ninhbinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/374",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Kim Sơn",
                            "maDonVi": "376",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://ninhbinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/376",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nho Quan",
                            "maDonVi": "372",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://ninhbinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/372",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Ninh Bình",
                            "maDonVi": "369",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://ninhbinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/369",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Tam Điệp",
                            "maDonVi": "370",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://ninhbinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/370",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Yên Mô",
                            "maDonVi": "377",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://ninhbinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/377",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Yên Khánh",
                            "maDonVi": "375",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://ninhbinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/375",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Ninh Thuận",
                    "maDonVi": "58",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://ninhthuan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bác Ái",
                            "maDonVi": "584",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://ninhthuan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/584",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ninh Phước",
                            "maDonVi": "587",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://ninhthuan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/587",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ninh Hải",
                            "maDonVi": "586",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://ninhthuan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/586",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ninh Sơn",
                            "maDonVi": "585",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://ninhthuan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/585",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Phan Rang-Tháp Chàm",
                            "maDonVi": "582",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://ninhthuan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/582",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thuận Nam",
                            "maDonVi": "589",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://ninhthuan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/589",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thuận Bắc",
                            "maDonVi": "588",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://ninhthuan.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/588",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Phú Thọ",
                    "maDonVi": "25",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://phutho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cẩm Khê",
                            "maDonVi": "235",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phutho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/235",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hạ Hoà",
                            "maDonVi": "231",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phutho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/231",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lâm Thao",
                            "maDonVi": "237",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phutho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/237",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Việt Trì",
                            "maDonVi": "227",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phutho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/227",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Phú Thọ",
                            "maDonVi": "228",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phutho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/228",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phù Ninh",
                            "maDonVi": "233",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phutho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/233",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tam Nông",
                            "maDonVi": "236",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phutho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/236",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thanh Thuỷ",
                            "maDonVi": "239",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phutho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/239",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thanh Sơn",
                            "maDonVi": "238",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phutho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/238",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thanh Ba",
                            "maDonVi": "232",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phutho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/232",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tân Sơn",
                            "maDonVi": "240",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phutho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/240",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Yên Lập",
                            "maDonVi": "234",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phutho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/234",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đoan Hùng",
                            "maDonVi": "230",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phutho.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/230",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Phú Yên",
                    "maDonVi": "54",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://phuyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Tuy Hoà",
                            "maDonVi": "555",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phuyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/555",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Sông Cầu",
                            "maDonVi": "557",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phuyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/557",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phú Hoà",
                            "maDonVi": "563",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phuyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/563",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Sông Hinh",
                            "maDonVi": "561",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phuyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/561",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Sơn Hòa",
                            "maDonVi": "560",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phuyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/560",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tuy An",
                            "maDonVi": "559",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phuyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/559",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tây Hoà",
                            "maDonVi": "562",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phuyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/562",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đông Hòa",
                            "maDonVi": "564",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phuyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/564",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đồng Xuân",
                            "maDonVi": "558",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://phuyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/558",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Quảng Ninh",
                    "maDonVi": "22",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://quangninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ba Chẽ",
                            "maDonVi": "202",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/202",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bình Liêu",
                            "maDonVi": "198",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/198",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cô Tô",
                            "maDonVi": "207",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/207",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hoành Bồ",
                            "maDonVi": "204",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/204",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hải Hà",
                            "maDonVi": "201",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/201",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Cẩm Phả",
                            "maDonVi": "195",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/195",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Hạ Long",
                            "maDonVi": "193",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/193",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Móng Cái",
                            "maDonVi": "194",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/194",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Uông Bí",
                            "maDonVi": "196",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/196",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Quảng Yên",
                            "maDonVi": "206",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/206",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Đông Triều",
                            "maDonVi": "205",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/205",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tiên Yên",
                            "maDonVi": "199",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/199",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Vân Đồn",
                            "maDonVi": "203",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/203",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đầm Hà",
                            "maDonVi": "200",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/200",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Quảng Bình",
                    "maDonVi": "44",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://quangbinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bố Trạch",
                            "maDonVi": "455",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangbinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/455",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lệ Thủy",
                            "maDonVi": "457",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangbinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/457",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Minh Hóa",
                            "maDonVi": "452",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangbinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/452",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành Phố Đồng Hới",
                            "maDonVi": "450",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangbinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/450",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Ba Đồn",
                            "maDonVi": "458",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangbinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/458",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Quảng Ninh",
                            "maDonVi": "456",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangbinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/456",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Quảng Trạch",
                            "maDonVi": "454",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangbinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/454",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tuyên Hóa",
                            "maDonVi": "453",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangbinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/453",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Quảng Trị",
                    "maDonVi": "45",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://quangtri.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cam Lộ",
                            "maDonVi": "468",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangtri.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/468",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Gio Linh",
                            "maDonVi": "466",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangtri.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/466",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hướng Hóa",
                            "maDonVi": "465",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangtri.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/465",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hải Lăng",
                            "maDonVi": "470",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangtri.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/470",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Đông Hà",
                            "maDonVi": "461",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangtri.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/461",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Quảng Trị",
                            "maDonVi": "462",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangtri.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/462",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Triệu Phong",
                            "maDonVi": "469",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangtri.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/469",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Vĩnh Linh",
                            "maDonVi": "464",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangtri.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/464",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đa Krông",
                            "maDonVi": "467",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangtri.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/467",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Quảng Nam",
                    "maDonVi": "49",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://quangnam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bắc Trà My",
                            "maDonVi": "515",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangnam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/515",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Duy Xuyên",
                            "maDonVi": "508",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangnam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/508",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hiệp Đức",
                            "maDonVi": "512",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangnam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/512",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nam Trà My",
                            "maDonVi": "516",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangnam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/516",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nam Giang",
                            "maDonVi": "510",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangnam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/510",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nông Sơn",
                            "maDonVi": "519",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangnam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/519",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Núi Thành",
                            "maDonVi": "517",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangnam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/517",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Hội An",
                            "maDonVi": "503",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangnam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/503",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Tam Kỳ",
                            "maDonVi": "502",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangnam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/502",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Điện Bàn",
                            "maDonVi": "507",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangnam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/507",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phú Ninh",
                            "maDonVi": "518",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangnam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/518",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phước Sơn",
                            "maDonVi": "511",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangnam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/511",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Quế Sơn",
                            "maDonVi": "509",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangnam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/509",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thăng Bình",
                            "maDonVi": "513",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangnam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/513",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tiên Phước",
                            "maDonVi": "514",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangnam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/514",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tây Giang",
                            "maDonVi": "504",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangnam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/504",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đông Giang",
                            "maDonVi": "505",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangnam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/505",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đại Lộc",
                            "maDonVi": "506",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangnam.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/506",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Quảng Ngãi",
                    "maDonVi": "51",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://quangngai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ba Tơ",
                            "maDonVi": "535",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangngai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/535",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bình Sơn",
                            "maDonVi": "524",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangngai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/524",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lý Sơn",
                            "maDonVi": "536",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangngai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/536",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Minh Long",
                            "maDonVi": "531",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangngai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/531",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mộ Đức",
                            "maDonVi": "533",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangngai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/533",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nghĩa Hành",
                            "maDonVi": "532",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangngai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/532",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Quảng Ngãi",
                            "maDonVi": "522",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangngai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/522",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Sơn Tây",
                            "maDonVi": "530",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangngai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/530",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Sơn Hà",
                            "maDonVi": "529",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangngai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/529",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Sơn Tịnh",
                            "maDonVi": "527",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangngai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/527",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Trà Bồng",
                            "maDonVi": "525",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangngai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/525",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tây Trà",
                            "maDonVi": "526",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangngai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/526",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tư Nghĩa",
                            "maDonVi": "528",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangngai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/528",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đức Phổ",
                            "maDonVi": "534",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://quangngai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/534",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Sóc Trăng",
                    "maDonVi": "94",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://soctrang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Châu Thành",
                            "maDonVi": "942",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://soctrang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/942",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cù Lao Dung",
                            "maDonVi": "945",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://soctrang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/945",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Kế Sách",
                            "maDonVi": "943",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://soctrang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/943",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Long Phú",
                            "maDonVi": "946",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://soctrang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/946",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mỹ Xuyên",
                            "maDonVi": "947",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://soctrang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/947",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mỹ Tú",
                            "maDonVi": "944",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://soctrang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/944",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Sóc Trăng",
                            "maDonVi": "941",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://soctrang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/941",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Ngã Năm",
                            "maDonVi": "948",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://soctrang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/948",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Vĩnh Châu",
                            "maDonVi": "950",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://soctrang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/950",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thạnh Trị",
                            "maDonVi": "949",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://soctrang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/949",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Trần Đề",
                            "maDonVi": "951",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://soctrang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/951",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Sơn La",
                    "maDonVi": "14",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://sonla.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bắc Yên",
                            "maDonVi": "121",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://sonla.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/121",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mai Sơn",
                            "maDonVi": "125",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://sonla.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/125",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mường La",
                            "maDonVi": "120",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://sonla.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/120",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mộc Châu",
                            "maDonVi": "123",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://sonla.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/123",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Sơn La",
                            "maDonVi": "116",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://sonla.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/116",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phù Yên",
                            "maDonVi": "122",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://sonla.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/122",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Quỳnh Nhai",
                            "maDonVi": "118",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://sonla.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/118",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Sông Mã",
                            "maDonVi": "126",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://sonla.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/126",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Sốp Cộp",
                            "maDonVi": "127",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://sonla.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/127",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thuận Châu",
                            "maDonVi": "119",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://sonla.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/119",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Vân Hồ",
                            "maDonVi": "128",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://sonla.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/128",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Yên Châu",
                            "maDonVi": "124",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://sonla.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/124",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Tây Ninh",
                    "maDonVi": "72",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://tayninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bến Cầu",
                            "maDonVi": "711",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tayninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/711",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Châu Thành",
                            "maDonVi": "708",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tayninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/708",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Dương Minh Châu",
                            "maDonVi": "707",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tayninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/707",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Gò Dầu",
                            "maDonVi": "710",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tayninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/710",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hòa Thành",
                            "maDonVi": "709",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tayninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/709",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Tây Ninh",
                            "maDonVi": "703",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tayninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/703",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Trảng Bàng",
                            "maDonVi": "712",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tayninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/712",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tân Châu",
                            "maDonVi": "706",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tayninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/706",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tân Biên",
                            "maDonVi": "705",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tayninh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/705",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Thái Nguyên",
                    "maDonVi": "19",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://thainguyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Sông Công",
                            "maDonVi": "165",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thainguyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/165",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Thái Nguyên",
                            "maDonVi": "164",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thainguyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/164",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Phổ Yên",
                            "maDonVi": "172",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thainguyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/172",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phú Bình",
                            "maDonVi": "173",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thainguyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/173",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phú Lương",
                            "maDonVi": "168",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thainguyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/168",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Võ Nhai",
                            "maDonVi": "170",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thainguyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/170",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đại Từ",
                            "maDonVi": "171",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thainguyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/171",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Định Hóa",
                            "maDonVi": "167",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thainguyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/167",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đồng Hỷ",
                            "maDonVi": "169",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thainguyen.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/169",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Thái Bình",
                    "maDonVi": "34",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://thaibinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hưng Hà",
                            "maDonVi": "339",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thaibinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/339",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Kiến Xương",
                            "maDonVi": "343",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thaibinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/343",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Thái Bình",
                            "maDonVi": "336",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thaibinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/336",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Quỳnh Phụ",
                            "maDonVi": "338",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thaibinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/338",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thái Thụy",
                            "maDonVi": "341",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thaibinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/341",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tiền Hải",
                            "maDonVi": "342",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thaibinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/342",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Vũ Thư",
                            "maDonVi": "344",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thaibinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/344",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đông Hưng",
                            "maDonVi": "340",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thaibinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/340",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Thanh Hóa",
                    "maDonVi": "38",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bá Thước",
                            "maDonVi": "386",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/386",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cẩm Thủy",
                            "maDonVi": "390",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/390",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hoằng Hóa",
                            "maDonVi": "399",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/399",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hà Trung",
                            "maDonVi": "392",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/392",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hậu Lộc",
                            "maDonVi": "400",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/400",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lang Chánh",
                            "maDonVi": "388",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/388",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mường Lát",
                            "maDonVi": "384",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/384",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nga Sơn",
                            "maDonVi": "401",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/401",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Ngọc Lặc",
                            "maDonVi": "389",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/389",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Như Thanh",
                            "maDonVi": "403",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/403",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Như Xuân",
                            "maDonVi": "402",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/402",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nông Cống",
                            "maDonVi": "404",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/404",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Sầm Sơn",
                            "maDonVi": "382",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/382",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Thanh Hóa",
                            "maDonVi": "380",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/380",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Bỉm Sơn",
                            "maDonVi": "381",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/381",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Quan Sơn",
                            "maDonVi": "387",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/387",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Quan Hóa",
                            "maDonVi": "385",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/385",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Quảng Xương",
                            "maDonVi": "406",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/406",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thiệu Hóa",
                            "maDonVi": "398",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/398",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thường Xuân",
                            "maDonVi": "396",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/396",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thạch Thành",
                            "maDonVi": "391",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/391",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Thọ Xuân",
                            "maDonVi": "395",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/395",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Triệu Sơn",
                            "maDonVi": "397",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/397",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tĩnh Gia",
                            "maDonVi": "407",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/407",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Vĩnh Lộc",
                            "maDonVi": "393",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/393",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Yên Định",
                            "maDonVi": "394",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/394",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Đông Sơn",
                            "maDonVi": "405",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thanhhoa.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/405",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Thừa Thiên Huế",
                    "maDonVi": "46",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://thuathienhue.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện A Lưới",
                            "maDonVi": "481",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thuathienhue.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/481",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nam Đông",
                            "maDonVi": "483",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thuathienhue.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/483",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phong Điền",
                            "maDonVi": "476",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thuathienhue.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/476",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Huế",
                            "maDonVi": "474",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thuathienhue.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/474",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Hương Thủy",
                            "maDonVi": "479",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thuathienhue.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/479",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Hương Trà",
                            "maDonVi": "480",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thuathienhue.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/480",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phú Lộc",
                            "maDonVi": "482",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thuathienhue.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/482",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Phú Vang",
                            "maDonVi": "478",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thuathienhue.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/478",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Quảng Điền",
                            "maDonVi": "477",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://thuathienhue.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/477",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Tiền Giang",
                    "maDonVi": "82",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://tiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cai Lậy",
                            "maDonVi": "820",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/820",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Châu Thành",
                            "maDonVi": "821",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/821",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Chợ Gạo",
                            "maDonVi": "822",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/822",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cái Bè",
                            "maDonVi": "819",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/819",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Gò Công Đông",
                            "maDonVi": "824",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/824",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Gò Công Tây",
                            "maDonVi": "823",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/823",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Mỹ Tho",
                            "maDonVi": "815",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/815",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Cai Lậy",
                            "maDonVi": "817",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/817",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Gò Công",
                            "maDonVi": "816",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/816",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tân Phú Đông",
                            "maDonVi": "825",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/825",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tân Phước",
                            "maDonVi": "818",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tiengiang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/818",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Trà Vinh",
                    "maDonVi": "84",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://travinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Châu Thành",
                            "maDonVi": "847",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://travinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/847",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Càng Long",
                            "maDonVi": "844",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://travinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/844",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cầu Ngang",
                            "maDonVi": "848",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://travinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/848",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Cầu Kè",
                            "maDonVi": "845",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://travinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/845",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Duyên Hải",
                            "maDonVi": "850",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://travinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/850",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Trà Vinh",
                            "maDonVi": "842",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://travinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/842",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Duyên Hải",
                            "maDonVi": "851",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://travinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/851",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tiểu Cần",
                            "maDonVi": "846",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://travinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/846",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Trà Cú",
                            "maDonVi": "849",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://travinh.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/849",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Tuyên Quang",
                    "maDonVi": "08",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://tuyenquang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Chiêm Hóa",
                            "maDonVi": "073",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tuyenquang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/073",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Hàm Yên",
                            "maDonVi": "074",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tuyenquang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/074",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lâm Bình",
                            "maDonVi": "071",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tuyenquang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/071",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Nà Hang",
                            "maDonVi": "072",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tuyenquang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/072",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Tuyên Quang",
                            "maDonVi": "070",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tuyenquang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/070",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Sơn Dương",
                            "maDonVi": "076",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tuyenquang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/076",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Yên Sơn",
                            "maDonVi": "075",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://tuyenquang.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/075",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Vĩnh Phúc",
                    "maDonVi": "26",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://vinhphuc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bình Xuyên",
                            "maDonVi": "249",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://vinhphuc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/249",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lập Thạch",
                            "maDonVi": "246",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://vinhphuc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/246",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Vĩnh Yên",
                            "maDonVi": "243",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://vinhphuc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/243",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Phúc Yên",
                            "maDonVi": "244",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://vinhphuc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/244",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Sông Lô",
                            "maDonVi": "253",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://vinhphuc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/253",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tam Đảo",
                            "maDonVi": "248",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://vinhphuc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/248",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tam Dương",
                            "maDonVi": "247",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://vinhphuc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/247",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Vĩnh Tường",
                            "maDonVi": "252",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://vinhphuc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/252",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Yên Lạc",
                            "maDonVi": "251",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://vinhphuc.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/251",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Vĩnh Long",
                    "maDonVi": "86",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://vinhlong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện  Vũng Liêm",
                            "maDonVi": "859",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://vinhlong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/859",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Bình Tân",
                            "maDonVi": "863",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://vinhlong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/863",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Long Hồ",
                            "maDonVi": "857",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://vinhlong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/857",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mang Thít",
                            "maDonVi": "858",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://vinhlong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/858",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Vĩnh Long",
                            "maDonVi": "855",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://vinhlong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/855",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Bình Minh",
                            "maDonVi": "861",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://vinhlong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/861",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Tam Bình",
                            "maDonVi": "860",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://vinhlong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/860",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Trà Ôn",
                            "maDonVi": "862",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://vinhlong.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/862",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                },
                {
                    "tenDonVi": "Sở Giáo dục và Đào tạo Tỉnh Yên Bái",
                    "maDonVi": "15",
                    "urlInfo": {
                        "title": "Hệ thống cấp 3",
                        "url": "https://yenbai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh",
                        "level": 1
                    },
                    "childPages": [
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Lục Yên",
                            "maDonVi": "135",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://yenbai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/135",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Mù Căng Chải",
                            "maDonVi": "137",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://yenbai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/137",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thành phố Yên Bái",
                            "maDonVi": "132",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://yenbai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/132",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Thị xã Nghĩa Lộ",
                            "maDonVi": "133",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://yenbai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/133",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Trạm Tấu",
                            "maDonVi": "139",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://yenbai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/139",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Trấn Yên",
                            "maDonVi": "138",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://yenbai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/138",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Văn Chấn",
                            "maDonVi": "140",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://yenbai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/140",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Văn Yên",
                            "maDonVi": "136",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://yenbai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/136",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        },
                        {
                            "tenDonVi": "Phòng Giáo dục và Đào tạo Huyện Yên Bình",
                            "maDonVi": "141",
                            "loaiDonVi": "2",
                            "urlList": [
                                {
                                    "title": "Hệ thống cấp 1,2",
                                    "url": "https://yenbai.tsdc.edu.vn/dang-ky-ho-so-tuyen-sinh/141",
                                    "level": 2
                                },
                                {
                                    "title": "Hệ thống mầm non",
                                    "url": "",
                                    "level": 3
                                }
                            ]
                        }
                    ]
                }
            ]
            if(json){
                this.setState({listDomains: json});
            }
        }catch(err){

        }
    }
    
    handleChange = (value) => {
        let selected = this.state.listDomains.find((obj) => obj.maDonVi == value);
        let childLevel3 = [], childLevel2 = [];
        if(selected){
            selected.childPages.map((obj) => {


                if (obj.urlList && obj.urlList.length){
                    childLevel3.push({
                        ...obj,
                        urlInfo: obj.urlList.find((o) => o.level === 3)
                    });
                    childLevel2.push({
                        ...obj,
                        urlInfo: obj.urlList.find((o) => o.level === 2)
                    });
                }else{
                    childLevel2.push(obj);
                }

            });
            this.setState({selected, childLevel3, childLevel2});
        }
    }

    hanldeClick = () => {
        if(this.state.selected){
            console.log(this.state.childLevel2)

            if (this.state.childLevel2.length){
                if ( this.state.childLevel2[this.state.childLevel2.length - 1].urlInfo){
                    let arr = this.state.childLevel2[this.state.childLevel2.length - 1].urlInfo.url.split('/');
                    arr[arr.length - 1] = this.state.selected.maDonVi;
                    this.setState({visible: true, urlReplace: arr.join('/')});
                }else{

                    this.setState({visible: true, urlReplace: this.state.childLevel2[this.state.childLevel2.length - 1].url+'/'});
                }

            }

        }
    }

    render() {
        const { Option } = Select;
        const { Panel } = Collapse;
        return (
            <div className="menu-register-content">
                <Fade left>
                    <h2 style={{textAlign: "center"}}>Đăng ký tuyển sinh</h2>
                </Fade>
                <Fade right>
                    <div className="menu-register-content_select">
                        <Select
                            showSearch
                            placeholder="Nhập tên sở giáo dục để tìm kiếm"
                            optionFilterProp="children"
                            onChange={this.handleChange}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {this.state.listDomains.map((web, index) => <Option key={index} value={web.maDonVi}>{web.tenDonVi}</Option>)}
                        </Select>
                        <Button type="primary" onClick={this.hanldeClick}>Đăng ký</Button>
                    </div>
                </Fade>
                <Modal
                    visible={this.state.visible}
                    onCancel={() => this.setState({visible: false})}
                >
                    {
                        this.state.selected ? <h3 style={{textAlign: "center"}}>{this.state.selected.tenDonVi}</h3> : null
                    }
                    {
                        this.state.selected ?
                        <>
                        <Button className="btn-primary" href={this.state.selected.urlInfo.url} target="_blank" type="default">Tuyển sinh vào lớp 10</Button>
                        <Collapse bordered={false} defaultActiveKey={['1']}>
                            <Panel header="Tuyển sinh vào mầm non, lớp 1, lớp 6 " key="1">
                                <ul>
                                    <li>
                                        <a target="_blank" rel="noopener noreferrer" href={this.state.urlReplace} title={this.state.selected.tenDonVi}>{this.state.selected.tenDonVi}</a>
                                    </li>
                                    {this.state.childLevel2.map((obj, i) => <li key={i}>
                                        <a target="_blank" rel="noopener noreferrer" href={obj.urlInfo ? obj.urlInfo.url : "#"} title={obj.tenDonVi}>{obj.tenDonVi}</a>
                                    </li>)}
                                </ul>
                            </Panel>
                            {/* <Panel header="Tuyển sinh vào mầm non" key="2">
                                <ul>
                                    {this.state.childLevel3.map((obj, i) => <li key={i}>
                                        <a target="_blank" rel="noopener noreferrer" href={obj.urlInfo ? obj.urlInfo.url : "#"} title={obj.tenDonVi}>{obj.tenDonVi}</a>
                                    </li>)}
                                </ul>
                            </Panel> */}
                        </Collapse>
                        </>: null
                    }
                </Modal>
            </div>
        )
    }
}
