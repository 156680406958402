import React, { Component } from 'react';
import {Layout, Icon} from 'antd';
import './Footer.scss';

export default class Footer extends Component {
    componentDidMount() {
        window.addEventListener('scroll', (e) => {
            let scrollEl = document.querySelector('.scroll-top');
            if(window.scrollY >= 300){
                if(scrollEl && (scrollEl.style.display === 'none' || !scrollEl.style.display)){
                    scrollEl.style.display = 'block';
                }
            }else{
                if(scrollEl && scrollEl.style.display === 'block'){
                    scrollEl.style.display = 'none';
                }
            }
        });
    }

    render() {
        return (
            <Layout.Footer className="footer">
                <button className="scroll-top" title="Scroll to top" onClick={() => window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })}>
                    <Icon type="arrow-up"/>
                </button>
                <div className="footer-site">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="about-us-ft mb-30">
                                    <img src="/assets/logo-white.png" alt="" style={{ width: 200 }} />
                                    <h3>Tổng công ty Giải pháp doanh nghiệp Viettel</h3>
                                    <p>
                                        Số 1 đường Trần Hữu Dực, Phường Mỹ Đình 2, Quận Nam Từ Liêm, Hà Nội,
                                        Việt Nam.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-4">
                                <div className="row justify-content-between">
                                    <div className="col-sm-12">
                                        <div className="support-customer mb-30">
                                            <h3 className="title-footer">HỖ TRỢ KHÁCH HÀNG</h3>
                                            <ul>
                                                <li>
                                                    <i className="fa fa-phone" /> 18008000
                                                    <span className="small"> (Nhánh số 2)</span>
                                                </li>

                                                <li>
                                                    <i className="ico-4" />
                                                    <a href="https://solutions.viettel.vn" target="_blank">
                                                        https://solutions.viettel.vn
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </Layout.Footer>
        )
    }
}
