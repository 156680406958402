import React, { Component } from 'react';
import './MenuFeature.scss';
import { Icon } from 'antd';

export default class MenuFeature extends Component {
    render() {
        return (
            <div className="menu-feature-item">
                <div className="menu-feature-item_content">
                    <div className="menu-feature-item_content-icon">
                        <Icon type={this.props.icon}/>
                    </div>
                    <h3 className="menu-feature-item_content-title">
                        {this.props.title}
                    </h3>
                    <div className="menu-feature-item_content-desc">
                        {this.props.children}
                    </div>
                </div>
            </div>
        )
    }
}
