import React, { Component } from 'react';
import { Fade } from 'react-reveal';
import './NewContainer.scss';

class NewItem extends Component{
    render(){
        return(
            <div className="news-container-content_item text-center">
                <div className="news-container-content_item-image">
                    <img src={this.props.image} alt={this.props.title}/>
                </div>
                <div className="news-container-content_item-title">
                    <p>{this.props.title}</p>
                </div>
            </div>
        )
    }
}

export default class NewContainer extends Component {
    render() {
        return (
            <div className="text-center">
                <h3>Giá 2.500.000/1 trường/1 năm (Đã tích hợp CSDL ngành GD)</h3>
            </div>
        )
    }
}
