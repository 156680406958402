import React, { Component } from 'react';
import './MenuHeader.scss';

export default class MenuHeader extends Component {
    render() {
        return (
            <div className="menu-header">
                <div className="menu-header-icon">
                    <span className="menu-header-icon_path"></span>
                    <span className="menu-header-icon_path"></span>
                    <span className="menu-header-icon_path"></span>
                    <span className="menu-header-icon_path"></span>
                </div>
                <h2 className="menu-header-title">
                    {this.props.title}
                </h2>
                <div className="menu-header-desc">
                    {this.props.desc}
                </div>
            </div>
        )
    }
}
