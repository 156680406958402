import React from 'react';
import {Layout, List, Icon, Row, Col} from 'antd';
import './App.scss';
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import MenuHeader from './components/menu/MenuHeader';
import MenuContainer, {Item} from './components/menu/MenuContainer';
import MenuFeature from './components/menu/MenuFeature';
import MenuRegister from './components/menu/MenuRegister';
import NewContainer from './components/news/NewContainer';
import { Fade, Flip } from 'react-reveal';

function App() {
    return (
        <Layout>
            <Header/>
            <Layout.Content>
                <section className="menu-container" id="about">
                    <MenuHeader
                        title="Giới thiệu"
                        desc="SMAS là hệ thống phần mềm quản lý nhà trường do Viettel cung cấp cho Sở/Phòng giáo dục, các trường từ cấp mầm non đến cấp phổ thông và trung tâm giáo dục thường xuyên để thực hiện các nghiệp vụ quản lý xoay quanh học sinh và giáo viên.
"></MenuHeader>
                    <Fade left>
                        <MenuContainer>
                            <Item width={300}>
                                <img src="/assets/image1.png" alt="Ảnh mô tả số 1"/>
                            </Item>
                            <Item flex="1">
                                <h3>Lợi ích dành cho phụ huynh, học sinh</h3>
                                <List bordered={false}>
                                    <List.Item>
                                        <Icon type="info-circle"/> Nhận thông báo về điểm số & tình hình học tập của con.
                                    </List.Item>

                                    <List.Item>
                                        <Icon type="rocket"/>Nhận những thông báo quan trọng từ nhà trường một cách nhanh chóng, tức thì.
                                    </List.Item>

                                </List>
                            </Item>
                        </MenuContainer>
                    </Fade>
                    <Fade right>
                        <MenuContainer>
                            <Item flex="1">
                                <h3>Lợi ích dành cho cán bộ, giáo viên</h3>
                                <List bordered={false}>
                                    <List.Item>
                                    <Icon type="safety-certificate" /> Dễ dàng khai báo các dữ liệu đầu năm, quản lý hồ sơ và quá trình học tập, thi cử, đánh giá xếp loại của học sinh.
                                    </List.Item>
                                    <List.Item>
                                    <Icon type="notification" /> Tổ chức các kì thi và quản lý kì thi một cách hiệu quả.
                                    </List.Item>
                                    <List.Item>
                                    <Icon type="control" /> Quản lý danh mục chuẩn quốc gia như danh mục hành chính, danh mục đào tạo một cách dễ dàng.
                                    </List.Item>
                                    <List.Item>
                                        <Icon type="solution" /> Quản lý thông tin theo dõi sức khỏe hàng ngày, kết quả khám định kì hàng năm của học sinh thuộc đơn vị giáo dục.
                                    </List.Item>
                                </List>
                            </Item>
                            <Item width={300}>
                                <img src="/assets/image2.png" alt="Ảnh mô tả số 2"/>
                            </Item>
                        </MenuContainer>
                    </Fade>
                    <Fade left>
                        <MenuContainer>
                            <Item width={300}>
                                <img src="/assets/image3.png" alt="Ảnh mô tả số 3"/>
                            </Item>
                            <Item flex="1">
                                <h3>Lợi ích cho cấp quản lý Phòng, Sở Giáo dục</h3>
                                <List bordered={false}>
                                    <List.Item>
                                        <Icon type="dashboard" /> Với giao diện thân thiện,
                                        khoa học, hệ thống giúp tối thiểu hóa những công việc thủ công, thủ tục hành chính...Từ đó, gỉảm thiểu tối đa sai sót đến từ yếu tố con người, đảm bảo tính chính xác, minh bạch trong các thao tác nhập điểm số, hạnh kiểm...
                                    </List.Item>
                                    <List.Item>
                                    <Icon type="clock-circle" /> Tiết kiệm thời gian thực hiện báo cáo, thống kê theo quy định của Bộ Giáo dục Đào tạo.
                                    </List.Item>
                                    <List.Item>
                                    <Icon type="cloud-sync" /> Các thay đổi về mặt nghiệp vụ, các yêu cầu đa dạng trong quá trình vận hành nhà trường luôn được đáp ứng kịp thời
                                    </List.Item>
                                    <List.Item>
                                    <Icon type="safety-certificate" /> Triển khai hoàn toàn miễn phí, không cần đầu tư thiết bị cấu hình cao hay phần mềm quản trị phức tạp.
                                    </List.Item>
                                </List>
                            </Item>
                        </MenuContainer>
                    </Fade>
                </section>
                <section className="menu-feature">
                <MenuHeader
                    title="Tính năng"></MenuHeader>
                  <Row type="flex">
                      <Col xs={24} sm={12} md={8} lg={6}>
                          <Flip top>
                            <MenuFeature title="Quản lý học sinh & cán bộ" icon="setting">
                                Hồ sơ học sinh và cán bộ.
                                Điểm danh, quản lý vi phạm, đánh giá học sinh, quản lý khen thưởng..
                                Phân công giảng dạy, giáo vụ, phân công chủ nhiệm, đánh giá xếp loại…
                            </MenuFeature>
                          </Flip>
                      </Col>
                      <Col xs={24} sm={12} md={8} lg={6}>
                          <Flip top>
                            <MenuFeature title="Chức năng quản lý hệ thống" icon="control">

                                Khai báo thông tin nhà trường & các dữ liệu đầu vào.
                                Xếp thời khóa biểu.
                                Giám sát nhập dữ liệu, kết chuyển và khôi phục dữ liệu
                            </MenuFeature>
                          </Flip>
                      </Col>

                      <Col xs={24} sm={12} md={8} lg={6}>
                          <Flip top>
                            <MenuFeature title="Báo cáo, thống kê" icon="snippets">
                                Báo cáo học sinh,
                                Báo cáo điểm, học bạ,
                                In, cấp kết quả học tập,
                                Báo cáo dữ liệu lên cấp Phòng, Sở...
                            </MenuFeature>
                          </Flip>
                      </Col>

                      <Col xs={24} sm={12} md={8} lg={6}>
                          <Flip bottom>
                            <MenuFeature title="Tra cứu, thống kê" icon="file-search">
                                Tra cứu thông tin học sinh.
                                Tra cứu kết quả học tập.
                                Tra cứu cán bộ quản lý, giáo viên, nhân viên.
                                Tra cứu lớp học.

                            </MenuFeature>
                        </Flip>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={6}>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={6}>
                          <Flip bottom>
                            <MenuFeature title="Service hệ thống" icon="deployment-unit">
                                Service cấp mã.
                                Service đồng bộ danh mục.
                                Service tiếp nhận dữ liệu.

                            </MenuFeature>
                        </Flip>
                      </Col>
                      <Col xs={24} sm={12} md={12} lg={6}>

                      </Col>
                      <Col xs={24} sm={12} md={12} lg={6}>
                      </Col>
                  </Row>
              </section>
              <section className="menu-register" id="register">
                  {/*<MenuRegister/>*/}
              </section>
              <section className="news-container" id="package">

                    <div className={'price_div'}>
                        <div className="menu-header">

                            <h2 className="menu-header-title text-center">
                                Giá dịch vụ

                            </h2>

                        </div>
                        <NewContainer/>
                    </div>


              </section>
            </Layout.Content>
            <Footer/>
        </Layout>
    );
}

export default App;
