import React, { Component } from 'react';
import { Layout, Button, Carousel, Dropdown, Menu } from 'antd';
import HeadShake from 'react-reveal/HeadShake';
import './Header.scss';

export default class Header extends Component {
    menuList = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <a target="_blank" rel="noopener noreferrer" href="https://qlts.tsdc.edu.vn/">Hệ thống quản lý nhà trường SMAS</a>
                </Menu.Item>

            </Menu>
        )
    }
    render() {
        return (
            <Layout.Header className="header">
                <div className="header-menu">
                    <div className="logo"></div>
                    <ul>
                        <li>
                            <a href="#about">Giới thiệu</a>
                        </li>
                        {/*<li>*/}
                        {/*    <a href="#register">Đăng ký tuyển sinh</a>*/}
                        {/*</li>*/}
                        <li>
                            <a href="#package">Gói phần mềm</a>
                        </li>
                        {/* <li>
                            <a href="#news">Tin tức</a>
                        </li> */}
                    </ul>
                    {/*<Dropdown overlay={this.menuList} trigger={['click']}>*/}
                    {/*    <Button className="pull-right" style={{margin: "14px 0 14px 5px"}} type="primary">Đăng nhập</Button>*/}
                    {/*</Dropdown>*/}
                    {/*<Button href="#register" className="hidden-lg pull-right" style={{margin: "14px 0"}} type="primary">Đăng ký tuyển sinh</Button>*/}
                </div>
                <Carousel className="header-carousel">
                    <div className="header-title">
                        <HeadShake effect="fadeInUp">
                            <h1>HỆ THỐNG QUẢN LÝ NHÀ TRƯỜNG SMAS</h1>
                        </HeadShake>
                    </div>
                </Carousel>
            </Layout.Header>
        )
    }
}
